<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BaseSelect
		:value="value"
		:placeholder="placeholderComp"
		:label="labelComp"
		:options="localeProvinceList"
		:validation-rules="{ required: required }"
		:validation-messages="{ required: requiredErrorMsgLabel }"
		:automation-id="getAutomationId('province')"
		@change="onChange"
	/>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component is responsible to render a province.
 */
import BaseSelect from '@/components/common/base/BaseSelect.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import IdMixin from '@/mixins/id';
extend('required', required);
// @vue/component
@Component({
	name: 'ProvinceSelect',
	components: {
		BaseSelect
	},
	mixins: [IdMixin],
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: String,
			default: '',
			required: true
		},
		provinces: {
			type: Array,
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		requiredErrorMsg: {
			type: String,
			default: ''
		},
		automationId: {
			type: String,
			required: true
		},
		label: {
			type: String,
			default: null
		},
		hidePlaceholder: {
			type: Boolean,
			default: false
		}
	}
})
export default class ProvinceSelect extends Vue {
	/**
	 * return required error messages
	 */
	get requiredErrorMsgLabel() {
		return this.requiredErrorMsg ? this.requiredErrorMsg : this.$t('error.provinceRequired');
	}

	get labelComp() {
		return this.label ? this.label : this.$t('label.province');
	}

	get placeholderComp() {
		return this.hidePlaceholder ? null : this.$t('placeholder.province');
	}

	get localeProvinceList() {
		let provinces;
		if (this.provinces === null) {
			provinces = [
				{ value: 'AB', text: this.$t('province.ab') },
				{ value: 'BC', text: this.$t('province.bc') },
				{ value: 'PE', text: this.$t('province.pei') },
				{ value: 'MB', text: this.$t('province.mb') },
				{ value: 'NB', text: this.$t('province.nb') },
				{ value: 'NS', text: this.$t('province.ns') },
				{ value: 'NU', text: this.$t('province.nu') },
				{ value: 'ON', text: this.$t('province.on') },
				{ value: 'QC', text: this.$t('province.qc') },
				{ value: 'SK', text: this.$t('province.sk') },
				{ value: 'NL', text: this.$t('province.nf') },
				{ value: 'NT', text: this.$t('province.tno') },
				{ value: 'YT', text: this.$t('province.yu') }
			];
			// toUpperCase() ensures a case insensitive sort
			return provinces.sort((a, b) => a.text.toUpperCase().localeCompare(b.text.toUpperCase()));
		}
		return this.provinces.sort((a, b) => a.text.toUpperCase().localeCompare(b.text.toUpperCase()));
	}

	/**
	 * Handle onchange event
	 * @param {string} value The value.
	 */
	onChange(value) {
		this.$emit('change', value);
	}
}
</script>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
		"label": {
			"province": "Province"
		},
		"error": {
			"provinceRequired": "Please provide your province."
		},
		"placeholder": {
			"province": "Select a province"
		},
		"province": {
			"ab": "Alberta",
			"bc": "British Columbia",
			"mb": "Manitoba",
			"nb": "New Brunswick",
			"nf": "Newfoundland and Labrador",
			"tno": "Northwest Territories",
			"ns": "Nova Scotia",
			"nu": "Nunavut",
			"on": "Ontario",
			"pei": "Prince Edward Island",
			"qc": "Quebec",
			"sk": "Saskatchewan",
			"yu": "Yukon"
		}
	},
	"fr": {
		"label": {
			"province": "Province"
		},
		"error": {
			"provinceRequired": "Veuillez indiquer votre province."
		},
		"placeholder": {
			"province": "Choisir la province"
		},
		"province": {
			"ab": "Alberta",
			"bc": "Colombie-Britannique",
			"pei": "Île-du-Prince-Édouard",
			"mb": "Manitoba",
			"nb": "Nouveau-Brunswick",
			"ns": "Nouvelle-Écosse",
			"nu": "Nunavut",
			"on": "Ontario",
			"qc": "Québec",
			"sk": "Saskatchewan",
			"nf": "Terre-Neuve-et-Labrador",
			"tno": "Territoires du Nord-Ouest",
			"yu": "Yukon"
		}
	}
}
</i18n>
