<template data-tracking-id="dental-input">
	<BFormInput
		:id="inputId"
		v-mask="'XXXXX'"
		:value="value"
		:placeholder="placeholder"
		:no-wheel="true"
		:automation-id="getAutomationId('dental')"
		:label="label"
		:aria-label="ariaLabel"
		@blur="onBlur"
		@input="onInput"
	/>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component is renders an dental input field
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { mask } from 'vue-the-mask';
import { BFormInput } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'DentalInput',
	components: {
		BFormInput
	},
	directives: { mask },
	mixins: [IdMixin],
	props: {
		isRequired: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop is used to determine what to display above the email input field.
		 */
		label: {
			type: String,
			default: ''
		},
		/**
		 * This prop is used to determine what to display above the email input field.
		 */
		ariaLabel: {
			type: String,
			default: ''
		},
		/**
		 * this prop is used to display contextual text inside the input
		 */
		placeholder: {
			type: String,
			default: ''
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		},
		inputId: {
			type: String,
			required: true
		},
		/**
		 * value is the value that will be used by this component to populate the input fields.
		 */
		value: {
			type: String,
			default: null
		}
	}
})
export default class DentalInput extends Vue {
	/**
	 * This function emits to the parent component the value entered into the dental code field.
	 */
	onInput(dentalCode) {
		this.$emit('input', dentalCode);
	}

	onBlur() {
		this.$emit('leave');
	}
}
</script>

<style lang="scss" scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>
