<template>
	<CoverageTemplate class="mb-5" :page-title="$t('title')" :full-width="true">
		<BRow class="h-80">
			<BCol class="h-100" cols="auto" lg="8" md="auto" sm="auto">
				<BenefitsBookletDownloadCard
					:description="$t('booklet.description')"
					:download-link-name="$t('booklet.downloadLink')"
					automation-id="myCoverage"
				></BenefitsBookletDownloadCard>
				<template v-if="securityPolicy && securityPolicy.suppressCoverage">
					<BaseCard automation-id="base-card">
						<template v-for="(coverage, index) in suppressCoverageText">
							<p :key="index" class="mb-0">{{ coverage }}</p>
						</template>
					</BaseCard>
				</template>
				<template v-else>
					<BaseCollapse
						:automation-id="getAutomationId('dental-coverage')"
						class="search-collapse"
						:button-label="$t('searchPanel.tabText')"
						:visible="isVisible"
						:icon-two="['fal', 'search']"
						button-variant="primary"
						@input="changeIsVisible"
					>
						<div class="collapse-content">
							<BRow>
								<BCol cols="12">
									<p>{{ $t('searchPanel.text') }}</p>
								</BCol>
							</BRow>
							<BRow>
								<BCol class="d-flex"
									><DentalSearchInputs
										:errors="errors"
										:error-state="errorState"
										:validation-rules="{ required: true }"
										:validation-messages="{ required: 'error' }"
										@input="saveDentalList"
										@blur="validateDentalList(false)"
										@remove="validateDentalList(false)"
									/>
								</BCol>
							</BRow>
							<BRow class="province-row" no-gutters>
								<BCol cols="11" lg="6" md="6" sm="8">
									<ProvinceSelect
										:value="selectedProvince"
										:provinces="provinces"
										:hide-placeholder="true"
										class="pt-4 province-select"
										:label="$t('province')"
										:required="true"
										:automation-id="getAutomationId('changeAddressInfo')"
										@change="updateProvince"
									/>
								</BCol>
							</BRow>
							<BRow no-gutters>
								<BCol cols="11" lg="6" md="6" sm="8">
									<DentalProvidersSelect
										:value="specialty"
										:provider-specialties="providerSpecialtiesList"
										:required="true"
										:automation-id="getAutomationId('changeAddressInfo')"
										@change="updateSpecialty"
								/></BCol>
							</BRow>
							<BRow>
								<BCol>
									<BaseButton
										class="search-button m-0"
										variant="primary"
										:label="$t('button.search')"
										pill
										:automation-id="getAutomationId('search-dental-codes')"
										:data-tracking-id="getAutomationId('search-dental-codes')"
										type="submit"
										@click="searchDental"
									></BaseButton>
								</BCol>
							</BRow>
						</div>
					</BaseCollapse>
					<DentalSearchResults v-if="searchResults" :search-results="searchResults" />
				</template>
			</BCol>
		</BRow>
	</CoverageTemplate>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Benefits from '@/models/coverage/BenefitsScepter';
import Member from '@/models/MemberCardInfo';
import DentalSuppressCoverage from '@/models/suppressed-coverages/dental.js';
import CoverageTemplate from './CoverageTemplate.vue';
import BenefitsBookletDownloadCard from '@/components/coverage/BenefitsBookletDownloadCard.vue';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import BaseCollapse from '@/components/common/base/BaseCollapse';
import DentalSearchInputs from '@/components/coverage/dental/DentalSearchInputs.vue';
import ProvinceSelect from '@/components/common/ProvinceSelect';
import DentalProvidersSelect from '@/components/coverage/dental/DentalProvidersSelect';
import DentalSearchResults from '@/components/coverage/dental/DentalSearchResults';
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { BRow, BCol } from 'bootstrap-vue';
import { isNumeric } from '@/utils/NumberUtil';
import IdMixin from '@/mixins/id';
import { startSpinner, stopSpinner } from '@/mixins/spinner';

// @vue/component
@Component({
	name: 'DentalPageScepter',
	components: {
		CoverageTemplate,
		BRow,
		BCol,
		BenefitsBookletDownloadCard,
		BaseButton,
		BaseCollapse,
		DentalSearchInputs,
		ProvinceSelect,
		DentalProvidersSelect,
		DentalSearchResults,
		BaseCard
	},
	mixins: [IdMixin, BreadcrumbsManager],
	watch: {
		locale() {
			if (this.securityPolicy?.suppressCoverage) {
				this.getSuppressedCoverageText();
			} else {
				this.getDentalSpecialties();
				this.getProvinces();
				if (this.errors.length > 0) {
					this.validateDentalList(false);
				}
				if (this.searchResults) {
					this.searchDental();
				}
			}
		}
	}
})
export default class DentalPageScepter extends Vue {
	isVisible = true;
	selectedProvince = '';
	provinces = null;
	specialty = '';
	providerSpecialtiesList = null;
	dentalCodeList = null;
	errors = [];
	errorState = null;
	searchResults = null;
	securityPolicy = null;
	suppressCoverageText = null;

	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	async created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.dental', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.dental', 'fr') }
			]
		);
		this.securityPolicy = await JSON.parse(sessionStorage.getItem('securityPolicy'));
		if (this.securityPolicy?.suppressCoverage) {
			this.getSuppressedCoverageText();
		} else {
			this.getDentalSpecialties();
			this.getProvinces();
			this.getProvinceOfAdjudication();
		}
	}

	/**
	 * Retrieve the list of provinces to be used from the API.
	 */
	async getProvinces() {
		// Get the list of provinces.
		var tmpProvinces = await Benefits.getDentalProvinces(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		);

		// Sort the list of provinces so that is is displayed alphabetically in the dropdown.
		if (tmpProvinces) {
			var sortedProvinces = [];
			for (var propName in tmpProvinces) {
				sortedProvinces.push({
					value: propName,
					text: tmpProvinces[propName]
				});
			}
			sortedProvinces.sort(function (a, b) {
				if (a.value < b.value) {
					return -1;
				}
				if (a.value > b.value) {
					return 1;
				}
				return 0;
			});
		}
		this.provinces = sortedProvinces;
	}

	/**
	 *  Get the list of provider specialties from the API.
	 */
	async getDentalSpecialties() {
		this.providerSpecialtiesList = await Benefits.getDentalSpecialties(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		);
		// Make the General practitioner the default value.
		this.specialty = 'GP';
	}

	/**
	 * Get the member from the API so we can set the selected province to the members provice of adjudication when the page loads for the user.
	 */
	async getProvinceOfAdjudication() {
		const member = await Member.getMemberDetail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		);
		this.selectedProvince = member?.provinceOfAdjudication;
	}
	/**
	 * Retirieve the text that is displayed for members with suppressed coverage.
	 */
	async getSuppressedCoverageText() {
		this.suppressCoverageText = await DentalSuppressCoverage.getSuppressedDentalBenefits(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		);
	}

	/*
	 * Change visibility of the collaspe.
	 */
	changeIsVisible() {
		this.isVisible = !this.isVisible;
	}

	/**
	 * Save the province locally.
	 */
	updateProvince(province) {
		this.selectedProvince = province;
	}
	/**
	 * save the specialty
	 */
	updateSpecialty(specialty) {
		this.specialty = specialty;
	}

	saveDentalList({ value, validate }) {
		this.dentalCodeList = value;
		if (validate) {
			this.validateDentalList(false);
		}
	}

	async searchDental() {
		//Clear the search results to ensure a fresh set of results, and hide the DentalSearchResults component.
		this.searchResults = null;
		startSpinner();
		// Call the validation method to determine if all the dental codes are valid, and can be sent to the API.
		var isValid = this.validateDentalList(true);

		if (isValid) {
			// Set the search results with the response from the API.
			const response = await Benefits.searchDental(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale,
				this.specialty,
				this.selectedProvince,
				this.dentalCodeList
			);
			// If the response returned was valid then display the response to the user, if not then display the API error to the specific inputs.
			if (response.status === 200) {
				this.searchResults = response.data;
			} else {
				this.displayAPIError(response.data.coverages);
			}
		}
		// Turn off the loading modal.
		stopSpinner();
	}

	/**
	 * This methiod is used to validate all the rules identified for dental code inputs before sending the values to the API.
	 */
	validateDentalList(buttonClicked) {
		// Create/reset all the error variables so they can be used fresh each time the method is called.
		this.errors = [];
		this.errorState = null;
		let errorCount = 0;
		let hasError = false;
		let hasOrthodonticsError = false;
		let hasOrthodonticsErrorNumber;
		let hasLabError = false;
		let hasLabErrorNumber;
		let hasMaterialError = false;
		let hasMaterialErrorNumber;
		let hasNumericError = false;
		let hasNumericErrorNumber;
		let hasInvalidError = false;
		let hasInvalidErrorNumber;
		let isValid = false;

		// First check if the member has added a value in a dental input, and make sure is not an empty value.
		// If it is then display the 'required' error message.
		if (
			this.dentalCodeList &&
			this.dentalCodeList?.some(Boolean) &&
			!(this.dentalCodeList.length === 1 && this.dentalCodeList[0] === '')
		) {
			// Loop through the dental code list to determine if any of the codes are invalid.
			for (let i = 0; i < this.dentalCodeList.length; i++) {
				// Check to make sure the input is a valid length. If not then display the invalid error.
				if (this.dentalCodeList[i].length !== 0 && this.dentalCodeList[i].length !== 5) {
					// Check to see if a invalid error already exists.
					if (hasInvalidError === false) {
						// If it does not then increment the error count and set the error number, and change the flag to true and set the Invalid error number
						//That will be used to correlate the error number displayed next to the input with the error message displayed above the dental inputs.
						errorCount++;
						hasInvalidError = true;
						hasInvalidErrorNumber = errorCount;
						this.errors.push(`(${hasInvalidErrorNumber}) ${this.$t('error.invalid')}`);
						this.errorState = false;
					}
					// Send the inputs index and the error number to be displayed.
					this.displayErrors(i, hasInvalidErrorNumber);
				}
				// Check if the dental code value is between 80000 and 90000, if it is then display the Orthondotics error, and follow the same logic as the previous check.
				else if (this.dentalCodeList[i] > 80000 && this.dentalCodeList[i] < 90000) {
					if (hasOrthodonticsError === false) {
						errorCount++;
						hasOrthodonticsError = true;
						hasOrthodonticsErrorNumber = errorCount;
						this.errors.push(`(${hasOrthodonticsErrorNumber}) ${this.$t('error.orthodontics')}`);
						this.errorState = false;
					}
					// Send the inputs index and the error number to be displayed.
					this.displayErrors(i, hasOrthodonticsErrorNumber);
					// Check if the dental code value is a specific set of numbers, if it is then display the Lab error, and follow the same logic as the first check.
				} else if (
					this.dentalCodeList[i] === '99111' ||
					this.dentalCodeList[i] === '99333' ||
					this.dentalCodeList[i] === '98888' ||
					this.dentalCodeList[i] === '98889'
				) {
					if (hasLabError === false) {
						errorCount++;
						hasLabError = true;
						hasLabErrorNumber = errorCount;
						this.errors.push(`(${hasLabErrorNumber}) ${this.$t('error.lab')}`);
						this.errorState = false;
					}
					// Send the inputs index and the error number to be displayed.
					this.displayErrors(i, hasLabErrorNumber);
					// Check if the dental code value is a specific number, if it is then display the material error, and follow the same logic as the first check.
				} else if (this.dentalCodeList[i] === '99555') {
					if (hasMaterialError === false) {
						errorCount++;
						hasMaterialError = true;
						hasMaterialErrorNumber = errorCount;
						this.errors.push(`(${hasMaterialErrorNumber}) ${this.$t('error.material')}`);
						this.errorState = false;
					}

					this.displayErrors(i, hasMaterialErrorNumber);
					// Check if the dental code value is not empty, and if it's numeric, if it's not then display the numeric error.
					// This will most likely never be hit because the input fields are masked, but it's here just in case a user finds a way around it.
				} else if (this.dentalCodeList[i] !== '' && !isNumeric(this.dentalCodeList[i])) {
					if (hasNumericError === false) {
						errorCount++;
						hasNumericError = true;
						hasNumericErrorNumber = errorCount;
						this.errors.push(`(${hasNumericErrorNumber}) ${this.$t('error.numeric')}`);
						this.errorState = false;
					}

					this.displayErrors(i, hasNumericErrorNumber);
					// If All the validation passes then remove all errors from that input.
				} else {
					document.getElementById(`dental-input-id-${i}`).style.border = 'solid 1px #8d8d8d';
					// this is to remove the aria-describedby attribute that is used for accessibility so the screen reader knows which error goes with a specific input.
					document.getElementById(`dental-input-id-${i}`).removeAttribute('aria-describedby');
					document.getElementById(`feedback-input-id-${i}`).style.display = 'none';
					document.getElementById(`feedback-input-id-${i}`).textContent = null;
				}
			}
			// Display an error if not input is used.
		} else {
			// Clear the errors.
			document.getElementById(`dental-input-id-0`).style.border = 'solid 1px #8d8d8d';
			document.getElementById(`feedback-input-id-0`).style.display = 'none';
			// Only do this validation if the member clicked the button.
			if (buttonClicked) {
				document.getElementById(`dental-input-id-0`).style.border = 'solid 1px #D3080C';
				document.getElementById(`feedback-input-id-0`).textContent = null;
				this.errors.push(this.$t('error.min'));
				this.errorState = false;
				hasError = true;
			}
		}
		// If there is no errors, and the hasError boolean is false then set isValid so the API will be called with the correct inputs.
		if (errorCount === 0 && !hasError) {
			isValid = true;
		}
		return isValid;
	}

	/**
	 * This method takes in an index of the dental code value in the array, and uses it to find the correct feedback inputs, and dental inputs to display an error on to.
	 * It also takes the error number to know which error number to display next to the input so that the member can quickly identify the error.
	 */
	displayErrors(index, errorNumber) {
		document.getElementById(`feedback-input-id-${index}`).textContent = errorNumber;
		document.getElementById(`feedback-input-id-${index}`).style.display = 'initial';
		document.getElementById(`dental-input-id-${index}`).style.border = 'solid 1px #D3080C';
		document
			.getElementById(`dental-input-id-${index}`)
			.setAttribute('aria-describedby', `error-${errorNumber}`);
	}

	/**
	 * This method is called after the response comes back from the API if there are any issues with error codes.
	 * It goes through the list of error codes, and gets the index of the invalid dental code in the dental code list that the member has entered
	 * to know which dental input to show the error number next too.
	 *
	 */
	displayAPIError(errorCodes) {
		// Set errorState and push an error to be displayed on the invalid feedback above the dental inputs fields.
		this.errorState = false;
		this.errors.push(`(1) ${this.$t('error.invalid')}`);
		// Loop through the error codes to display error numbers next to the inputs to correlate with the errors displayed on the screen.
		for (let i = 0; i < errorCodes?.length; i++) {
			const index = this.dentalCodeList.indexOf(errorCodes[i].code);
			this.displayErrors(index, '1');
		}
	}
}
</script>

<style lang="scss" scoped>
.collapse-content {
	padding: 30px;
	@media (max-width: 576px) {
		padding: 15px;
	}
}
.search-collapse {
	padding-bottom: 15px;
}
.benefit-booklet ::v-deep .card-body {
	padding-bottom: 30px;
}
.province-row {
	border-top: 1px solid $gray-light;
}
.province-select ::v-deep option:disabled {
	display: none;
}
.province-select ::v-deep label {
	width: 310px;
}
</style>

<i18n>
{
	"en": {
		"title": "Dental Coverage",
		"province": "Where will the service(s) be provided?",
		"breadcrumb": {
      		"home": "Home",
      		"myCoverage": "Plan Coverage",
      		"dental": "Dental"
    	},
		"booklet": {
			"description": "Download your benefits booklet to see your coverage, how often you can have procedures done, and what percentage of the costs we’ll cover.",
			"downloadLink": "Download your benefits booklet (PDF)"
		},
		"button": {
			"search": "Search"
		},
		"searchPanel": {
			"tabText": "Search by Code",
			"text": "We use dental codes to look up coverage for each dental procedure. Not sure where to find these codes? Your dental office can provide them to you."
		},
		"error": {
			"orthodontics": "For all orthodontic procedures, the only code you need to enter is  80000. This code covers all orthodontic procedures.",
			"lab": "Please remove the separate laboratory code. Lab fees are already factored into the cost, and a separate code is not needed.",
			"material": "Please remove the separate materials code. Material fees are already factored into the cost, and a separate code is not needed.",
			"min": "Please enter at least one dental procedure code.",
			"invalid": "There's an issue with this procedure code. This code may be invalid or may not be available with the selected provider type. ",
			"numeric": "The dental code you've entered is invalid. Dental codes can only contain numbers."
		}

	},
	"fr": {
		"title": "Couverture des soins dentaires",
		"province": "Où les services seront-ils rendus?",
		"breadcrumb": {
      		"home": "Accueil",
      		"myCoverage": "Couverture du régime",
     		"dental": "Soins dentaires"
    	},
		"booklet": {
			"description": "Téléchargez votre brochure de garanties pour connaître votre couverture, la fréquence à laquelle les procédures dentaires peuvent être effectuées et le pourcentage des coûts que nous remboursons.",
			"downloadLink": "Téléchargez votre brochure de garanties (PDF)"
		},
		"button": {
			"search": "Rechercher"
		},
		"searchPanel": {
			"tabText": "Recherche par code",
			"text": "Vous devez utiliser le code de procédure pour rechercher la couverture associée à un service dentaire. Vous n’avez pas ce code en main? Votre cabinet de soins dentaires peut vous le fournir."
		},
		"error": {
			"orthodontics": "Le code à entrer pour tous les traitements d’orthodontie est le 80000. Ce code couvre tous les traitements d’orthodontie.",
			"lab": "Veuillez retirer le code distinct pour les services de laboratoire. Les frais de laboratoire sont compris dans le coût, il n’est pas nécessaire d’utiliser un code distinct.",
			"material": "Veuillez retirer le code distinct pour le matériel. Les frais liés au matériel sont compris dans le coût, il n’est pas nécessaire d’utiliser un code distinct.",
			"min": "Fournir au moins un code dentaire.",
			"invalid": "Il y a un problème avec ce code de procédure. Ce code est peut-être invalide ou n'est peut-être pas disponible pour le type de fournisseur sélectionné.",
			"numeric": "Le code dentaire entré n’est pas valide. Les codes dentaires ne contiennent que des chiffres."
		}

	}
}
</i18n>
