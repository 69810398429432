<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BaseSelect
		:value="value"
		:label="labelComp"
		class="dental-select"
		:is-plain="true"
		:options="localeDentalProviders()"
		:automation-id="getAutomationId('dental-provider')"
		@change="onChange"
	/>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component is responsible to render a province.
 */
import BaseSelect from '@/components/common/base/BaseSelect.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import IdMixin from '@/mixins/id';
extend('required', required);
// @vue/component
@Component({
	name: 'DentalProvidersSelect',
	components: {
		BaseSelect
	},
	mixins: [IdMixin],
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: String,
			default: '',
			required: true
		},
		providerSpecialties: {
			type: Object,
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		requiredErrorMsg: {
			type: String,
			default: ''
		},
		automationId: {
			type: String,
			required: true
		},
		label: {
			type: String,
			default: null
		}
	}
})
export default class DentalProvidersSelect extends Vue {
	get labelComp() {
		return this.label ? this.label : this.$t('label.provider');
	}

	/**
	 * Handle onchange event
	 * @param {string} value The value.
	 */
	onChange(value) {
		this.$emit('change', value);
	}

	localeDentalProviders() {
		// Check the provider specialties and sort through them to put them in alphabetical order.
		// For some reason just returning the list will put them in the dropdown randomly.
		if (this.providerSpecialties) {
			var sortedProviderSpecialties = [];
			for (var propName in this.providerSpecialties) {
				sortedProviderSpecialties.push({
					value: propName,
					text: this.providerSpecialties[propName]
				});
			}
			sortedProviderSpecialties.sort(function (a, b) {
				if (a.value < b.value) {
					return -1;
				}
				if (a.value > b.value) {
					return 1;
				}
				return 0;
			});
		}
		return sortedProviderSpecialties;
	}
}
</script>

<style lang="scss" scoped>
.modal-font-weight {
	font-weight: 300;
}

.dental-select ::v-deep option:disabled {
	display: none;
}
</style>
<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
		"label": {
			"provider": "Who is providing the service?"
		}
	},
	"fr": {
		"label": {
			"provider": "Quelle personne fournit les services?"
		}
	}
}
</i18n>
