<template>
	<div>
		<BRow>
			<BCol cols="12">
				<BaseCard v-scroll-to class="base-card" automation-id="base-card">
					<BRow>
						<BCol class="h3 mb-4" cols="12" lg="10" md="12" sm="12">
							{{ $t('title') }}
						</BCol>
					</BRow>
					<BRow>
						<BCol class="mb-4" cols="12">
							{{ $t('description') }}
						</BCol>
					</BRow>
					<BRow>
						<BCol class="h4 mb-1" cols="12" lg="8" md="10" sm="12">
							{{ $t('overallProvisions') }}
						</BCol>
					</BRow>
					<ul>
						<template v-for="(provision, index) in searchResults.overallProvisions">
							<li :key="index">{{ provision }}</li>
						</template>
					</ul>
					<BRow>
						<BCol class="h5 mb-0" cols="12" lg="8" md="10" sm="12">
							{{ $t('lab.title') }}
						</BCol>
					</BRow>
					<BRow>
						<BCol class="mb-4" cols="12">
							{{ $t('lab.description') }}
						</BCol>
					</BRow>
					<BRow>
						<BCol class="h5 mb-0" cols="12" lg="8" md="10" sm="12">
							{{ $t('alt.title') }}
						</BCol>
					</BRow>
					<BRow>
						<BCol cols="12">
							{{ $t('alt.description') }}
						</BCol>
					</BRow>
				</BaseCard>
			</BCol>
		</BRow>
		<p class="h3 mb-3">{{ $t('provision') }}</p>
		<BRow v-for="(coverage, index) in searchResults.coverages" :key="index">
			<BCol cols="12">
				<BaseCard class="base-card" automation-id="base-card">
					<BRow>
						<BCol class="h3 mb-3" cols="12">
							{{ `${coverage.code} - ${coverage.shortDescription}` }}
						</BCol>
					</BRow>
					<BRow>
						<BCol class="h4 mb-1" cols="12">
							{{ $t('eligibility') }}
						</BCol>
					</BRow>
					<ul class="mb-0">
						<template v-for="(provision, provisionIndex) in coverage.provisions">
							<li :key="provisionIndex">{{ provision }}</li>
						</template>
					</ul>
				</BaseCard>
			</BCol>
		</BRow>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseCard from '@/components/common/card/BaseCard';
import { BRow, BCol } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'DentalSearchResults',
	components: {
		BaseCard,
		BRow,
		BCol
	},
	mixins: [IdMixin],
	props: {
		searchResults: {
			type: Object,
			default: null
		}
	}
})
export default class DentalSearchResults extends Vue {}
</script>

<i18n>
{
	"en": {
        "title": "Benefit Coverage Details - Dental",
        "description": "The following benefits are subject to any deductible, co-insurance or maximum amount shown in the Overall Provisions and the Benefit Provisions specified below.",
        "overallProvisions": "Overall Provisions",
        "provision": "Provisions",
        "eligibility": "Eligibility",
        "lab": {
            "title": "* Laboratory Expenses",
            "description": "Dental procedures that are identified with an asterisk have an additional laboratory expense that will be factored into the fee of the procedure."
        },
        "alt": {
            "title": "** Alternate Benefit",
            "description": "Dental procedures that are identified with a double asterisk are reduced to an alternate treatment. Where more than one course of treatment is available, Blue Cross will pay for the least expensive course of treatment that will provide a professionally adequate result."
        }
	},
	"fr": {
        "title": "Détails sur la couverture - Dentaire",
        "description": "Les garanties suivantes sont assujetties aux franchises, quotes-parts ou montants maximaux précisés dans les sections Dispositions générales et Dispositions relatives aux garanties ci-dessous.",
        "overallProvisions": "Dispositions générales",
        "provision": "Dispositions",
        "eligibility": "Admissibilité",
        "lab": {
            "title": "* Frais de laboratoire",
            "description": "Les traitements dentaires suivis d'un astérisque comportent des frais de laboratoire dont nous tiendrons compte dans le montant soumis."
        },
        "alt": {
            "title": "** Autre garantie",
            "description": "Les traitements dentaires suivis de deux astérisques sont assujettis à un autre traitement. Lorsque plus d'une forme de traitement est offerte, Croix Bleue paiera la forme la moins coûteuse fournissant un résultat adéquat et professionnel."
        }
	}
}
</i18n>
