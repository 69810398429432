<script setup>
import { onMounted, ref, getCurrentInstance, computed, watch, nextTick } from 'vue';
import store from '@/store/index';
// models
import DentalBenefit from '@/models/coverage/DentalBenefits.js';
import Dependent from '@/models/Dependent.js';
import ContactInfo from '@/models/ContactInfo';
// constants
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
// composables
import { useI18n } from '@/composables/useI18n';
import { useBreadcrumbs } from '@/composables/useBreadcrumbs';
import { useComponentId } from '@/composables/useComponentId';
// components
import { BForm, BFormInput, BInputGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseCollapse from '@/components/common/base/BaseCollapse.vue';
import BaseDropDown from '@/components/common/base/BaseDropDown.vue';
import BaseDropDownItem from '@/components/common/base/BaseDropDownItem.vue';
import ParticipantDropdown from '@/components/common/ParticipantDropdown.vue';
import ClaimRequirements from '@/components/coverage/ClaimRequirements.vue';
// pages
import CoverageTemplate from '@/pages/coverage/CoverageTemplate.vue';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;
const { getUniqueId, getAutomationId } = useComponentId();

const participants = ref(null);
const selectedParticipant = ref(null);
const selectedProvince = ref(null);
const memberDetails = ref(null);
const provinces = ref(null);
const selectedSpecialty = ref(null);
const specialties = ref(null);
const searchVisible = ref(true);
const codes = ref([{ code: '', position: 1 }]);
const refCodes = ref([]);
const errors = ref([]);
const apiErrors = ref([]);
const loaded = ref(false);
const searchResults = ref([]);
const results = ref(null);
const hasBenefits = ref(true);
const claimRequirements = ref([]);

// locale watch
watch(
	() => root.$i18n.locale,
	async () => {
		store.dispatch('updateLoading', true);
		loaded.value = false;
		await getData();
		setProvince(provinces.value.find((p) => p.value === selectedProvince.value.value));
		setSpecialty(specialties.value.find((s) => s.value === selectedSpecialty.value.value));
		if (searchResults.value.length > 0 || errors.value.length > 0) {
			await search();
		}
		loaded.value = true;
		store.dispatch('updateLoading', false);
	}
);

// computed
const addMoreCodesDisabled = computed(() => {
	return codes.value.filter((c) => c.code === '').length > 0 || codes.value.length >= 10;
});
const validateSearchForm = computed(() => {
	return !selectedProvince.value.value ? true : false;
});

// methods
async function checkCoverage() {
	store.dispatch('updateLoading', true);

	const email = sessionStorage.getItem('email');
	const token = sessionStorage.getItem('apiToken');
	const locale = root.$i18n.locale;
	const hasBenefits = await DentalBenefit.checkBenefits(
		email,
		selectedParticipant.value.participantId,
		token,
		locale
	);

	store.dispatch('updateLoading', false);

	return hasBenefits;
}
async function setActiveParticipant(data) {
	selectedParticipant.value = data.participant;
	hasBenefits.value = await checkCoverage();
	searchResults.value = [];
	codes.value = [{ code: '', position: 1 }];
	apiErrors.value = [];
	errors.value = [];
}
function setProvince(province) {
	const prov = provinces.value.find((p) => p.value === province?.value);
	selectedProvince.value = prov ? prov : { label: null, value: null };
	apiErrors.value = [];
}
function setSpecialty(spec) {
	selectedSpecialty.value = specialties.value.find((s) => s.value === spec.value);
	apiErrors.value = [];
}
async function addCodes() {
	codes.value.push({ code: '', position: codes.value.length + 1 });
	await nextTick();
	refCodes.value[refCodes.value.length - 1].querySelector('input').focus();
}
function codeAdded() {
	codes.value = codes.value.map((code) => {
		code.code = code.code.trim();
		return code;
	});
}
function removeCodes(code) {
	codes.value = codes.value
		.filter((c) => c.position !== code.position)
		.map((c, index) => {
			c.position = index + 1;
			return c;
		});
	errors.value = [];
	validateCodes();
}
function validateCodes(skipEmpty = false) {
	let errCodes;
	const addError = (code, err) => {
		const existing = errors.value.find((e) => e.error === err);
		if (existing) {
			existing.code = [...existing.code, code.code];
			existing.position = [...existing.position, code.position];
		} else {
			errors.value.push({
				position: [code.position],
				code: [code.code],
				error: err
			});
		}
	};

	errors.value = [];

	// validate api codes
	apiErrors.value.forEach((e) => {
		codes.value
			.filter((c) => e.code.includes(c.code))
			.forEach((code) => {
				addError(code, e.error);
			});
	});

	// get blank/empty codes
	errCodes = codes.value.filter((c) => c.code === '');
	if (
		!skipEmpty &&
		codes.value.filter((c) => c.code.length > 0).length === 0 &&
		errCodes.length > 0
	) {
		errCodes.forEach((errCode) => {
			addError(errCode, t('searchCollapse.error.none'));
		});
	}

	// validate non empty codes
	errCodes = codes.value.filter((c) => c.code.length > 0 && !c.code.match('^[0-9]{5}$'));
	if (errCodes.length > 0) {
		errCodes.forEach((errCode) => {
			addError(
				errCode,
				/[a-zA-Z]/g.test(errCode.code)
					? t('searchCollapse.error.alpha')
					: t('searchCollapse.error.fiveDigits')
			);
		});
	}

	// combine errors etc
	errors.value = errors.value.reduce((combined, error) => {
		if (error.code.length > 0) {
			const existingErr = combined?.find((e) => e.error === error.error);
			if (existingErr) {
				existingErr.errorNo = (combined.length + 1).toString();
				existingErr.code = [...existingErr.code, ...error.code];
				existingErr.position = [...existingErr.position, ...error.position];
			} else {
				error.errorNo = (combined.length + 1).toString();
				combined.push({ ...error });
			}
		}
		return combined;
	}, []);
	return errors.value.length === 0;
}
function hasCodeError(position) {
	return errors.value.find((e) => e.position.includes(position));
}
function getErrorNo(position) {
	return codes.value.length > 1 && errors.value.length > 0
		? errors.value.find((e) => e.position.includes(position))?.errorNo
		: '0';
}
function errorText(error) {
	return `${errors.value.length > 1 ? `(${error.errorNo})` : ''} ${error.error}`;
}
function showErrorSummary() {
	let arrErr = [];
	if (errors.value.length > 0) arrErr = [...errors.value].flatMap((e) => e.code);
	return arrErr.length === 0;
}
function searchResultHeader(result) {
	if (!('shortDescription' in result.service)) return result.code;
	return `${result.code} - ${result.service.shortDescription}`;
}
function searchResultsAgreements(agreements) {
	if (agreements) {
		const items = agreements
			.filter((item) => item.descriptions)
			.map((item) => {
				let header = '';
				let order = 99;
				switch (item.type.toLowerCase()) {
					case 'coinsurance':
						header = t('searchResults.agreements.coinsurance');
						order = 1;
						break;
					case 'benefit_maximum':
						header = t('searchResults.agreements.frequency');
						order = 2;
						break;
					case 'dental_pricing':
						header = t('searchResults.agreements.coveredAmount');
						order = 3;
						break;
					case 'fiscal_maximum':
						header = t('searchResults.agreements.max');
						order = 4;
						break;
				}
				return {
					order,
					header,
					content: item.descriptions ? item.descriptions : []
				};
			});
		return items.sort((a, b) => (a.order > b.order ? 1 : -1));
	} else {
		return [];
	}
}
async function getData() {
	const email = sessionStorage.getItem('email');
	const token = sessionStorage.getItem('apiToken');
	const locale = root.$i18n.locale;

	apiErrors.value = [];

	const values = await Promise.all([
		Dependent.getDependents(email, token, locale, true),
		DentalBenefit.getSpecialties(email, selectedParticipant.participantId, token, locale),
		DentalBenefit.getProvinces(email, selectedParticipant.participantId, token, locale),
		ContactInfo.getUserInfo(email, token, locale)
	]);
	participants.value = values[0];
	specialties.value = values[1];
	provinces.value = values[2];
	memberDetails.value = values[3];
	selectedParticipant.value =
		selectedParticipant.value !== null
			? participants.value.data.find(
					(p) => selectedParticipant.value.participantId === p.participantId
			  )
			: participants.value.data[0];

	useBreadcrumbs(
		[
			{ text: t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
			{ text: t('breadcrumb.dental', 'en') }
		],
		[
			{ text: t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
			{ text: t('breadcrumb.dental', 'fr') }
		]
	);
}
async function search() {
	if (validateCodes()) {
		store.dispatch('updateLoading', true);

		claimRequirements.value = [];
		const result = await DentalBenefit.getBenefits(
			sessionStorage.getItem('email'),
			selectedParticipant.value.participantId,
			sessionStorage.getItem('apiToken'),
			root.$i18n.locale,
			{
				specialty: selectedSpecialty.value.value,
				province: selectedProvince.value.value,
				codes: [...new Set(codes.value.filter((c) => c.code !== '').map((c) => c.code))]
			}
		);

		if (result.status === 400) {
			// api errors
			apiErrors.value = result.data.errors;
			validateCodes();
		}
		searchResults.value = result.data.results.filter((sr) => !('errors' in sr));

		if (errors.value.length === 0 && searchResults.value.length > 0) {
			await nextTick();
			setTimeout(() => {
				results.value.scrollIntoView({ behavior: 'smooth' });
			}, 200);
		}

		// construct the claim requirements array
		searchResults.value.forEach((result) => {
			const cr = result.service.claimRequirements ? result.service.claimRequirements : [];
			claimRequirements.value = [
				...claimRequirements.value,
				...cr.map((requirement) => {
					if (!claimRequirements.value.includes(requirement)) return requirement;
				})
			].filter((cr) => cr);
		});

		store.dispatch('updateLoading', false);
	}
}

// lifecycle methods
onMounted(async () => {
	try {
		store.dispatch('updateLoading', true);

		await getData();

		// check coverage for default participant
		hasBenefits.value = await checkCoverage();

		// temporary workaround for people from Quebec
		if (memberDetails.value.province === 'PQ') memberDetails.value.province = 'QC';
		setProvince(provinces.value.find((p) => p.value === memberDetails.value.province));
		setSpecialty(specialties.value.find((s) => s.value === 'GPR'));

		loaded.value = true;
		store.dispatch('updateLoading', false);
	} catch {
		store.dispatch('error');
	}
});
</script>

<template>
	<CoverageTemplate v-if="loaded" :page-title="t('pageTitle')" class="mb-4" full-width>
		<template v-if="participants.data.length > 1">
			<h2>{{ t('coverageHeading') }}</h2>
			<p>{{ t('coverageDescription') }}</p>
			<ParticipantDropdown
				class="mb-4"
				:selected-participant="selectedParticipant"
				:participants="participants.data"
				@click="setActiveParticipant"
			/>
		</template>
		<template v-if="!hasBenefits">
			<h2 class="h2-nocoverage">
				{{ t('noCoverageHeading') }}
			</h2>
			<p class="no-coverage-description">
				{{ t('noCoverageDescription') }}
			</p>
		</template>
		<BaseCollapse
			v-else
			:automation-id="getAutomationId('dental-coverage')"
			class="dental-search-collapse"
			:button-label="t('searchCollapse.button')"
			:visible="searchVisible"
			:icon-two="['fal', 'search']"
			button-variant="primary"
			@input="searchVisible = !searchVisible"
		>
			<div class="p-4">
				<p>{{ t('searchCollapse.text') }}</p>
				<h3>{{ t('searchCollapse.heading3') }}</h3>
				<p>{{ t('searchCollapse.example') }}</p>
				<BForm
					automation-id="dental-search-form"
					class="position-relative"
					@submit.stop.prevent="search"
				>
					<div class="d-flex flex-wrap">
						<div v-for="code in codes" :key="code.position" class="d-flex mb-3 align-items-center">
							<BInputGroup
								ref="refCodes"
								class="code-input-group"
								:class="[
									{ 'prepend-invisible': !hasCodeError(code.position) || errors.length === 1 }
								]"
								:prepend="hasCodeError(code.position) ? getErrorNo(code.position) : '0'"
							>
								<BFormInput
									v-model="codes[code.position - 1].code"
									class="code-input"
									:state="!hasCodeError(code.position)"
									@change="codeAdded"
								/>
							</BInputGroup>
							<BaseButton
								v-show="codes.length > 1"
								:id="`btn-remove-dental-code-${code.position - 1}`"
								:aria-label="t('searchCollapse.removeCode')"
								:pill="false"
								class="mr-2"
								variant="link"
								:automation-id="getAutomationId(`dental-code-remove-${code.position - 1}`)"
								:icon="['fal', 'times-circle']"
								icon-size="lg"
								type="button"
								@click="removeCodes(code)"
							/>
						</div>
					</div>

					<BFormInvalidFeedback :state="showErrorSummary()" class="mb-3">
						<div v-for="(error, index) in errors" :key="index">
							{{ errorText(error) }}
						</div>
					</BFormInvalidFeedback>

					<BaseButton
						:label="t('searchCollapse.addCode')"
						:icon="['fal', 'plus-circle']"
						icon-position="left"
						:pill="false"
						variant="link"
						:disabled="addMoreCodesDisabled"
						:automation-id="getAutomationId('dental-add-code')"
						@click="addCodes"
					/>

					<hr class="mb-4" />

					<label for="province-dropdown">{{ t('searchCollapse.province') }}</label>
					<BaseDropDown
						id="province-dropdown"
						class="ddl-province mb-4"
						:data-test-automation-id="getAutomationId('province-dropdown')"
						variant="secondary"
						caret-style="solid"
						:title="selectedProvince.label"
					>
						<template #default="{ variant }">
							<BaseDropDownItem
								v-for="province in provinces"
								:id="`${getUniqueId('province-dropdown-item')}-${parseInt(Math.random() * 1000)}`"
								:key="province.label"
								class="ddl-province-item"
								:test-id="getAutomationId('province-dropdown-item')"
								:active="province.value === selectedProvince.value"
								:variant="variant"
								:value="province.value"
								:label="province.label"
								@click="setProvince(province)"
							/>
						</template>
					</BaseDropDown>

					<label for="specialty-dropdown">{{ t('searchCollapse.provider') }}</label>
					<BaseDropDown
						id="specialty-dropdown"
						class="ddl-specialty mb-4"
						:data-test-automation-id="getAutomationId('specialty-dropdown')"
						variant="secondary"
						caret-style="solid"
						:title="selectedSpecialty.label"
					>
						<template #default="{ variant }">
							<BaseDropDownItem
								v-for="specialty in specialties"
								:id="`${getUniqueId('specialty-dropdown-item')}-${parseInt(Math.random() * 1000)}`"
								:key="specialty.label"
								class="ddl-specialty-item"
								:test-id="getAutomationId('specialty-dropdown-item')"
								:active="specialty.value === selectedSpecialty.value"
								:variant="variant"
								:value="specialty.value"
								:label="specialty.label"
								@click="setSpecialty(specialty)"
							/>
						</template>
					</BaseDropDown>
					<BaseButton
						class="d-block m-0"
						variant="primary"
						:label="t('searchCollapse.searchButton')"
						pill
						:automation-id="getAutomationId('search-dental-codes')"
						:data-tracking-id="getAutomationId('search-dental-codes')"
						type="submit"
						:disabled="validateSearchForm"
						@click="validateCodes()"
					></BaseButton>
				</BForm>
			</div>
		</BaseCollapse>
		<template v-if="searchResults.length > 0">
			<h2 ref="results" class="h2-results">
				{{ t('searchResults.results', { name: selectedParticipant.firstName }) }}
			</h2>
			<BaseCard v-for="result in searchResults" :key="result.code" class="search-result">
				<template v-if="'translatedCodeDisclaimer' in result" #top>
					<h3 class="h3-result">{{ searchResultHeader(result) }}</h3>
					<p class="font-weight-normal">
						{{ result.translatedCodeDisclaimer.content[0] }}
					</p>
				</template>
				<h3 v-if="!('translatedCodeDisclaimer' in result)" class="h3-result">
					{{ searchResultHeader(result) }}
				</h3>

				<BaseAlert
					v-if="'benefitWaitingPeriodDisclaimer' in result.service"
					alternate
					variant="warning"
					class="waiting-period-alert p-3"
				>
					<b class="d-block">{{ result.service.benefitWaitingPeriodDisclaimer.title }}</b>
					<div>
						{{ result.service.benefitWaitingPeriodDisclaimer.content[0] }}
					</div>
				</BaseAlert>

				<!-- DESKTOP VIEW -->
				<div class="d-none d-md-block mt-2">
					<table v-if="'agreements' in result.service" class="search-result-table">
						<tr
							v-for="agreement in searchResultsAgreements(result.service.agreements)"
							:key="agreement.order"
						>
							<td class="heading">{{ agreement.header }}</td>
							<td>
								<ul v-if="agreement.content?.length > 1">
									<li v-for="content in agreement.content" :key="content">
										{{ content }}
									</li>
								</ul>
								<template v-else> {{ agreement.content[0] }}</template>
							</td>
						</tr>
						<tr v-if="result.service.ageConditionDisclaimer?.title">
							<td></td>
							<td>
								<p :class="{ 'mb-0': result.service.ageConditionDisclaimer.content.length === 1 }">
									<b>{{ result.service.ageConditionDisclaimer.title }}</b>
									{{ result.service.ageConditionDisclaimer.content[0] }}
								</p>
								<template v-if="result.service.ageConditionDisclaimer.content.length > 1">
									<p
										v-for="ageCondition in result.service.ageConditionDisclaimer.content"
										:key="ageCondition"
										:class="{
											'mb-0': index === result.service.ageConditionDisclaimer.content.length - 1
										}"
									>
										<template v-if="index > 0">{{ ageCondition }}</template>
									</p>
								</template>
							</td>
						</tr>
					</table>
					<template v-else>
						<b>{{ t('searchCollapse.error.noCoverage1') }}</b>
						{{ t('searchCollapse.error.noCoverage2') }}
					</template>
					<template v-if="'labAndMaterialFeesDisclaimer' in result">
						<hr />
						<b>{{ result.labAndMaterialFeesDisclaimer.title }}</b>
						{{ result.labAndMaterialFeesDisclaimer.content[0] }}
					</template>
				</div>

				<!-- MOBILE VIEW -->
				<div class="d-md-none mt-2">
					<template v-if="'agreements' in result.service">
						<template
							v-for="(agreement, index) in searchResultsAgreements(result.service.agreements)"
						>
							<h4 :key="agreement.header" class="search-result-mobile-heading">
								{{ agreement.header }}
							</h4>
							<p
								:key="agreement.content[0]"
								:class="{
									'mb-0': searchResultsAgreements(result.service.agreements).length - 1 === index
								}"
							>
								{{ agreement.content[0] }}
							</p>
						</template>
						<template v-if="result.service.ageConditionDisclaimer?.title">
							<p
								:class="[
									{ 'mb-0': result.service.ageConditionDisclaimer.content.length === 1 },
									'mt-3'
								]"
							>
								<b>{{ result.service.ageConditionDisclaimer.title }}</b>
								{{ result.service.ageConditionDisclaimer.content[0] }}
							</p>
							<template v-if="result.service.ageConditionDisclaimer.content.length > 0">
								<p
									v-for="(ageCondition, index) in result.service.ageConditionDisclaimer.content"
									:key="ageCondition"
									:class="{
										'mb-0': index === result.service.ageConditionDisclaimer.content.length - 1
									}"
								>
									<template v-if="index > 0">{{ ageCondition }}</template>
								</p>
							</template>
						</template>
					</template>
					<template v-else>
						<hr />
						<b>{{ t('searchCollapse.error.noCoverage1') }}</b>
						{{ t('searchCollapse.error.noCoverage2') }}
					</template>
					<template v-if="'labAndMaterialFeesDisclaimer' in result">
						<hr />
						<b>{{ result.labAndMaterialFeesDisclaimer.title }}</b>
						{{ result.labAndMaterialFeesDisclaimer.content[0] }}
					</template>
				</div>
			</BaseCard>
			<ClaimRequirements
				v-if="claimRequirements?.length > 0"
				class="claim-requirements"
				:claim-requirements="claimRequirements"
			/>
		</template>
	</CoverageTemplate>
</template>

<style lang="scss" scoped>
b {
	font-weight: bold;
}
h2,
h3 {
	line-height: normal;
}
h3 {
	font-size: 18px;
}
.h2-results {
	font-size: 24px;
	margin-top: 1em;
	margin-bottom: 0.83em;
}
hr {
	border: none;
	border-top: 1px solid #e9e9e9;
}
label {
	font-family: $josefin-font-family;
	font-size: 18px;
	display: block;
	margin-bottom: 0;
}
.dental-search-collapse {
	& ::v-deep .icon-two {
		padding-left: 0 !important;
	}
}
.dental-search-collapse,
.search-result {
	max-width: 730px;
	& .h3-result {
		font-size: 21px;
		line-height: 1.5em;
	}
}
.claim-requirements {
	max-width: 730px;
	& ::v-deep h3 {
		font-size: 24px;
	}
}
.ddl-province,
.ddl-specialty {
	min-width: min(100%, 280px);
	max-width: 100%;
	& .ddl-province-item,
	& .ddl-specialty-item {
		min-width: min(100%, 280px);
		max-width: 100%;
	}
}
.code-input-group {
	& ::v-deep .input-group-prepend > .input-group-text {
		color: #dc3545;
		font-size: 14px;
		background-color: transparent;
		border: none;
		padding-left: 0;
		padding-right: 5px;
	}
	&.prepend-invisible > ::v-deep .input-group-prepend > .input-group-text {
		visibility: hidden;
	}
	&.prepend-hidden > ::v-deep .input-group-prepend > .input-group-text {
		display: none;
	}
	& .code-input {
		border-radius: 5px;
		width: 85px;
		height: 42px;
	}
}
.invalid-feedback {
	background-color: $error-background-pink;
	border-radius: 5px;
	padding: 10px;
}
.search-result-table {
	empty-cells: show;
	& ul {
		margin: 0;
	}
	& td {
		padding-bottom: 15px;
	}
	& tr:last-child td {
		padding-bottom: 0;
	}
	& .heading {
		vertical-align: text-top;
		font-weight: bold;
		padding-right: 3rem;
		white-space: nowrap;
		& div:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}
.search-result-mobile-heading {
	font-family: $lato-font-family;
	font-weight: bold;
	font-size: 17px;
}
.h2-nocoverage {
	font-size: 21px;
}
.no-coverage-description {
	font-weight: 300;
	font-size: 21px;
}
</style>

<i18n lang="json">
{
	"en": {
		"pageTitle": "Dental Coverage",
		"coverageHeading": "Who is the coverage for?",
		"coverageDescription": "Each participant on your plan may have different coverage. Select a participant to see coverage details for that person.",
		"noCoverageHeading": "Coverage not applicable",
		"noCoverageDescription": "This benefit is not available for the plan member you have selected. Please choose another plan member.",
		"searchCollapse": {
			"button": "Search by Code",
			"text": "We use dental codes to look up coverage for each dental procedure. Not sure where to find these codes? Your dental office can provide them to you.",
			"heading3": "Enter the procedure code(s) provided by your dental office.",
			"example": "(e.g. 11101)",
			"province": "Where will the service(s) be provided?",
			"provider": "Who is providing the service?",
			"addCode": "Add another code",
			"removeCode": "Remove code",
			"searchButton": "Search",
			"error": {
				"none": "Please enter at least one dental procedure code.",
				"alpha": "The dental code you've entered is invalid. Dental codes can only contain numbers.",
				"fiveDigits": "Please enter a 5-digit procedure code.",
				"noCoverage1": "Coverage not applicable.",
				"noCoverage2": "This benefit is not available for the plan member you have selected. Please choose another plan member."
			}
		},
		"searchResults": {
			"results": "Results for {name}",
			"agreements": {
				"coinsurance": "Percentage covered",
				"frequency": "Frequency",
				"max": "Maximum reimbursement",
				"coveredAmount": "Covered amount"
			}
		},
		"breadcrumb": {
			"home": "Home",
			"myCoverage": "Plan Coverage",
			"dental": "Dental"
		}
	},
	"fr": {
		"pageTitle": "Couverture des soins dentaires",
		"coverageHeading": "Qui a besoin de la couverture?",
		"coverageDescription": "Chaque personne assurée par votre régime pourrait avoir une couverture différente. Sélectionnez une personne assurée pour voir les détails de sa couverture.",
		"noCoverageHeading": "La couverture ne s'applique pas",
		"noCoverageDescription": "La couverture ne s'applique pas à l'adhérent(e) sélectionné(e). Veuillez choisir un(e) autre adhérent(e).",
		"searchCollapse": {
			"button": "Recherche par code",
			"text": "Vous devez utiliser le code de procédure pour rechercher la couverture associée à un service dentaire. Vous n'avez pas ce code en main? Votre cabinet de soins dentaires peut vous le fournir.",
			"heading3": "Entrez le ou les codes de procédure fournis par votre cabinet de soins dentaires.",
			"example": "(p. ex. 11101)",
			"province": "Où les services seront-ils rendus?",
			"provider": "Quelle personne fournit les services?",
			"addCode": "Ajouter un autre code",
			"removeCode": "Supprimer le code",
			"searchButton": "Rechercher",
			"error": {
				"none": "Fournir au moins un code dentaire.",
				"alpha": "Le code dentaire entré n'est pas valide. Les codes dentaires ne contiennent que des chiffres.",
				"fiveDigits": "Entrez un code de traitement à 5 chiffres.",
				"noCoverage1": "La couverture ne s'applique pas.",
				"noCoverage2": "La couverture ne s'applique pas à l'adhérent(e) sélectionné(e). Veuillez choisir un(e) autre adhérent(e)."
			}
		},
		"searchResults": {
			"results": "Résultats pour {name}",
			"agreements": {
				"coinsurance": "Pourcentage couvert",
				"frequency": "Fréquence",
				"max": "Remboursement maximal",
				"coveredAmount": "Montant admissible"
			}
		},
		"breadcrumb": {
			"home": "Accueil",
			"myCoverage": "Couverture du régime",
			"dental": "Soins dentaires"
		}
	}
}
</i18n>
