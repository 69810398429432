<template data-tracking-id="dental-input">
	<div class="dental-search-inputs">
		<BRow>
			<BCol class="h5">{{ $t('text') }}</BCol>
		</BRow>
		<BRow>
			<BCol class="mb-3">{{ $t('example') }}</BCol>
		</BRow>
		<BRow>
			<BCol class="dental-list d-flex flex-wrap">
				<!-- eslint-disable-next-line -->
				<BFormInvalidFeedback :id="`feedback-id`" :keys="errorsComputed" :force-show="false" class="invalid-feedback-main" aria-live="polite" :state="errorState" :data-test-automation-id="getAutomationId('feedback')" v-html="errorsComputed" />

				<div v-for="(dentalCode, index) in dentalList" :key="index" class="dental-inputs">
					<BFormInvalidFeedback
						:id="`feedback-input-id-${index}`"
						:force-show="false"
						class="invalid-feedback-input"
						aria-live="polite"
						:state="null"
						:data-test-automation-id="getAutomationId('feedback')"
					>
					</BFormInvalidFeedback>

					<DentalInput
						ref="dentalRef"
						:input-id="`dental-input-id-${index}`"
						:value="dentalCode"
						:aria-label="$t('text')"
						:is-required="true"
						:state="null"
						:automation-id="getAutomationId(`dental-code-${index}`)"
						:data-tracking-id="getAutomationId(`dental-code-${index}`)"
						class="dental-input"
						@leave="onBlur()"
						@input="saveDentalCode(index, $event)"
					/>

					<BaseButton
						:id="`remove-dental-btn-id-${index}`"
						ref="triggerButton"
						:aria-label="$t('remove')"
						visible="false"
						class="remove-dental-code-btn"
						:pill="false"
						variant="link"
						:automation-id="getAutomationId(`dental-code-remove${index}`)"
						:data-tracking-id="getAutomationId(`dental-code-remove${index}`)"
						:icon="['fal', 'times-circle']"
						type="button"
						@click="removeDentalCode(index)"
					/>
				</div>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<BaseButton
					v-if="dentalList.length < 30"
					:label="$t('addCode')"
					:aria-label="$t('addCode')"
					:icon="['fal', 'plus-circle']"
					icon-position="left"
					:pill="false"
					variant="link"
					class="mb-4 pt-0 pl-0"
					:automation-id="getAutomationId('dental-search-input')"
					:data-tracking-id="getAutomationId('dental-search-input')"
					@click="addDentalCode"
				/>
			</BCol>
		</BRow>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component is renders the dental input fields
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BRow, BCol } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton';
import DentalInput from '@/components/coverage/dental/DentalInput.vue';
import { BFormInvalidFeedback } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'DentalSearchInputs',
	components: {
		BRow,
		BCol,
		BaseButton,
		DentalInput,
		BFormInvalidFeedback
	},
	mixins: [IdMixin],
	props: {
		errors: {
			type: Array,
			default: () => []
		},
		errorState: {
			type: Boolean,
			default: null
		}
	}
})
export default class DentalSearchInputs extends Vue {
	feedbackId = this.getUniqueId('feedback');
	dentalList = [''];
	errorFeedback = null;
	invalidState = null;

	// gets the errors from the errors prop and creates the labels to be displayed above the dental inputs. The id is to be used by the aria-describedby attribute.
	get errorsComputed() {
		let errorsFormatted = '';
		for (let i = 0; i < this.errors.length; i++) {
			if (this.errors[i]) {
				errorsFormatted += `<label class="mb-2" id="error-${i + 1}"> ${
					this.errors[i]
				} </label> <br>`;
			}
		}
		return errorsFormatted !== '' ? errorsFormatted : null;
	}
	/**
	 *  Add a new empty value to the array to show another dental input field. Only 30 codes including the default one can be added.
	 */
	addDentalCode() {
		if (this.dentalList.length < 30) {
			this.dentalList.push('');
		}
		// Using nextTick so that the new input that gets displayed is also picked up when doing the querySelectorAll
		this.$nextTick(() => {
			[].forEach.call(document.querySelectorAll('.remove-dental-code-btn'), function (el) {
				el.style.visibility = 'visible';
			});
			//This will move the focus to the new component
			this.$refs.dentalRef[this.dentalList.length - 1].$el.focus();
		});
	}

	/**
	 * Update the dental code list with the new value, Have to use Vue.set() to actually have vue know the array updated.
	 */
	saveDentalCode(index, value) {
		var checkValidation = false;
		Vue.set(this.dentalList, index, value);
		if (value.length === 0 || value.length === 5) {
			checkValidation = true;
		}
		this.$emit('input', { value: this.dentalList, validate: checkValidation });
	}

	onBlur() {
		this.$emit('blur');
	}

	/**
	 * Remove dental code from the array.
	 */
	removeDentalCode(index) {
		this.dentalList.splice(index, 1);
		if (this.errors.length > 0) {
			this.$emit('remove');
		}
		// Only try and hide the remove buttons once the dental list only has 1 input displayed.
		if (this.dentalList.length === 1) {
			document.querySelectorAll('.remove-dental-code-btn')[0].style.visibility = 'hidden';
		}
		this.$nextTick(() => {
			//This will put the focus at the end or in the middle
			if (index > this.dentalList.length - 1) {
				this.$refs.dentalRef[this.dentalList.length - 1].$el.focus();
			} else {
				this.$refs.dentalRef[index].$el.focus();
			}
		});
	}
}
</script>

<style lang="scss" scoped>
.dental-search-inputs {
	min-height: 175px;
}
.bold-text {
	font-family: $font-family-headline;
	font-size: 18px;
}
.dental-list ::v-deep .form-group {
	margin-bottom: 0px;
}
.dental-input {
	max-width: 90px;
	width: 80%;
	float: left;
}
.dental-inputs {
	padding-right: 15px;
	margin-bottom: 15px;
	@media (max-width: 576px) {
		padding-right: 10px;
	}
}
.remove-dental-code-btn {
	transition: 0s;
	visibility: hidden;
	margin-top: 5px;
	font-size: 20px;
	width: 20%;
	float: left;
}
.form-control {
	font-family: 'Lato', sans-serif;
	box-shadow: none;
	font-weight: 400;
	background-color: $input-background-white;
	border-color: $input-border-white;
	border-radius: 5px;

	&:focus {
		border-color: $input-border-focus-blue;
	}
	&.is-valid,
	&.is-invalid {
		background-image: none;
	}
	&.is-invalid {
		border-color: $error-red;
		box-shadow: none;
	}
}
.invalid-feedback-input {
	width: 5%;
	float: left;
	text-align: left;
	font-size: 14px;
	color: $light-red;
	line-height: 17px;
	font-weight: 400;
	padding: 10px 18px 0px 0px;
	height: auto;
	border-radius: 5px;
	margin-top: 0;
}
.invalid-feedback-main {
	text-align: left;
	font-size: 14px;
	color: $light-red;
	line-height: 17px;
	font-weight: 400;
	background-color: $error-background-pink;
	border-color: $red;
	border-radius: 5px;
	padding: 10px;
	height: auto;
	margin-top: 0;
	margin-bottom: 10px;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
    "en": {
        "text": "Enter the procedure code(s) provided by your dental office.",
        "example": "(e.g. 11101)",
        "addCode": "Add another code",
		"remove": "Remove code"
	},
	"fr": {
	    "text": "Entrez le ou les codes de procédure fournis par votre cabinet de soins dentaires.",
        "example": "(p. ex. 11101)",
        "addCode": "Ajouter un autre code",
		"remove": "Supprimer le code"
	}
}
</i18n>
