<template>
	<BaseDropDown
		:id="getUniqueId('participant-dropdown')"
		:automation-id="getAutomationId('participant-dropdown')"
		:variant="variant"
		caret-style="solid"
		:title="isAllActive ? allLabelComputed : getFullName(selectedParticipant)"
		:sub-title="getsubTitle"
		:icon="showIcon ? 'user-circle' : null"
		class="participant-dropdown"
	>
		<template #default="{ style }">
			<BaseDropDownItem
				v-if="includeAll"
				:id="`${getUniqueId('participant-dropdown-item')}-all`"
				:test-id="getAutomationId('participant-dropdown-item')"
				:label="allLabelComputed"
				icon-left="user-circle"
				icon-left-size="lg"
				icon-active="check"
				icon-active-size="lg"
				:active="isAllActive"
				:variant="style"
				value="all"
				@click="setAllActive({ participantId: 'all' })"
			/>
			<BaseDropDownItem
				v-for="(participant, index) in participants.filter((p) => p.participantId !== 'all')"
				:id="`${getUniqueId('participant-dropdown-item')}-${participant.participantId}`"
				:key="participant.participantId"
				:automation-id="getAutomationId(`participant-dropdown-item-${index + 1}`)"
				:label="getFullName(participant)"
				:sub-label="showDob ? formatDob(participant.dateOfBirth) : null"
				:icon-left="showIcon ? 'user-circle' : null"
				icon-left-size="lg"
				icon-active="check"
				icon-active-size="lg"
				:active="participant.participantId === selectedParticipant.participantId"
				:variant="style"
				:value="participant.participantId"
				@click="setActiveParticipant(participant, index)"
			/>
		</template>
	</BaseDropDown>
</template>

<script setup>
import { defineEmits, defineProps, getCurrentInstance, computed } from 'vue';
import { format } from 'date-fns';
import { enCA, frCA } from 'date-fns/esm/locale';
// components
import BaseDropDown from '@/components/common/base/BaseDropDown.vue';
import BaseDropDownItem from '@/components/common/base/BaseDropDownItem.vue';
// composables
import { useComponentId } from '@/composables/useComponentId';
import { useI18n } from '@/composables/useI18n';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;
const props = defineProps({
	includeAll: {
		type: Boolean,
		default: false
	},
	participants: {
		type: Array,
		required: true
	},
	selectedParticipant: {
		type: Object,
		required: true
	},
	showIcon: {
		type: Boolean,
		default: true
	},
	showDob: {
		type: Boolean,
		default: true
	},
	variant: {
		type: String,
		default: 'primary'
	},
	allLabel: {
		type: String,
		default: null
	}
});
const emit = defineEmits(['click']);
const { getUniqueId, getAutomationId } = useComponentId();

// computed
const getsubTitle = computed(() => {
	return props.showDob && !isAllActive.value
		? formatDob(props.selectedParticipant.dateOfBirth)
		: null;
});
const isAllActive = computed(() => {
	return props.selectedParticipant.participantId === 'all';
});

const allLabelComputed = computed(() => {
	return props.allLabel ? props.allLabel : t('all');
});

// methods
function setActiveParticipant(participant, index = null) {
	if (participant.participantId !== props.selectedParticipant.participantId)
		emit('click', { participant, index });
}
function setAllActive(value) {
	emit('click', { participant: value });
}
function getFullName(participant) {
	return `${participant.firstName} ${participant.lastName}`;
}
function formatDob(dob) {
	return format(
		new Date(dob.substring(0, 4), dob.substring(4, 6) - 1, dob.substring(6, 8)),
		'dd MMM yyyy',
		{ locale: root.$i18n.locale === 'fr' ? frCA : enCA }
	);
}
</script>

<style lang="scss" scoped>
.participant-dropdown {
	min-width: min(390px, 100%);
	min-height: 58px;
	@include media-breakpoint-down(xs) {
		width: 100%;
	}
}
</style>

<i18n lang="json">
{
	"en": {
		"all": "All"
	},
	"fr": {
		"all": "Tout"
	}
}
</i18n>
