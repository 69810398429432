<!--
  Base Collapse 

  From Bootstrap: "Easily toggle visibility of almost any content on your pages in a vertically collapsing container. "

  Example how to use:
		## BUTTON ##
		<BaseCollapse button-label="Search your claims" button-variant="primary">
			<p>
				Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
				been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
				galley of type and scrambled it to make a type specimen book. 
			</p>
		</BaseCollapse>

		## LINK ##
		<BaseCollapse button-label="Something missing from your claim details?" button-variant="link">
			<p>
				Not all reductions to claim payouts are displayed. Sometimes your claim payments might be
				reduced based on things like eligible expenses, coordination of benefits (payouts to your
				other plan or your spouse’s plan) or payments made directly to your provider.
			</p>
		</BaseCollapse>
  -->
<template>
	<div class="base-collapse">
		<BaseButton
			:label="buttonLabel"
			:variant="buttonVariant"
			:class="[
				visible ? 'collapse-button-open' : 'collapse-button-closed',
				buttonVariant === 'link' ? 'button-link' : ''
			]"
			:pill="false"
			:icon="icon"
			:icon-position="iconPosition"
			:icon-rotation="iconRotation"
			:aria-controls="id"
			:aria-expanded="ariaExpanded"
			:icon-two="iconTwo"
			:icon-two-position="iconTwoPosition"
			:automation-id="getAutomationId('collapse')"
			@click="changeVisibility(!visible)"
		/>
		<BCollapse
			:id="id"
			v-bind="$attrs"
			:class="['collapse-content', buttonVariant !== 'link' ? 'collapse-content-background' : '']"
			:visible="visible"
			:appear="appear"
			:data-test-automation-id="getAutomationId('collapse')"
			:data-tracking-id="getAutomationId('collapse')"
		>
			<slot> </slot>
		</BCollapse>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id.js';
import { BCollapse } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton';

// @vue/component
@Component({
	name: 'BaseCollapse',
	components: {
		BCollapse,
		BaseButton
	},
	inheritedAttrs: false,
	mixins: [IdMixin],
	props: {
		/**
		 * From Bootstrap-vue: When set, and prop 'visible' is true on mount, will animate on initial mount
		 */
		appear: {
			type: Boolean,
			default: false
		},
		/**
		 * From Bootstrap-vue: When 'true', expands the collapse
		 */
		visible: {
			type: Boolean,
			default: null,
			required: true
		},
		/**
		 * From Bootstrap-vue: 	Used to set the `id` attribute on the rendered content, and used as the base to generate any additional element IDs as needed
		 */
		id: {
			type: String,
			default: `baseCollapse${Math.floor(Math.random() * 100)}`
		},
		/**
		 * Sets the button text
		 */
		buttonLabel: {
			type: String,
			default: ''
		},
		/**
		 * sets the variant for the button the default will be link
		 * as per design requires link variant mroe for accordions
		 */
		buttonVariant: {
			type: String,
			default: 'link'
		},
		/**
		 * icon associated with the button
		 */
		icon: {
			type: Array,
			default: () => ['fal', 'chevron-down']
		},
		/**
		 * Decide which side to display the icon
		 */
		iconPosition: {
			type: String,
			default: 'right'
		},
		/**
		 * icon associated with the button
		 */
		iconTwo: {
			type: Array,
			default: null
		},
		/**
		 * Decide which side to display the icon
		 */
		iconTwoPosition: {
			type: String,
			default: 'left'
		},
		/**
		 * Sets if the accordion requires a border at the bottom
		 */
		border: {
			type: Boolean,
			default: false
		},
		textFloat: {
			type: String,
			default: 'left'
		}
	}
})
export default class BaseCollapse extends Vue {
	changeVisibility(visible) {
		if (this.visible !== null) {
			this.$emit('input', visible);
		}
	}

	get ariaExpanded() {
		return this.visible !== null ? this.visible.toString() : false;
	}

	get iconRotation() {
		return !this.visible ? null : 180;
	}
}
</script>

<style lang="scss" scoped>
.base-collapse ::v-deep .btn-link {
	border: none;
	padding: 0px;
	text-align: left;
	height: initial;
}

.base-collapse ::v-deep .button-label {
	margin-bottom: 0px;
}

.collapse-button-closed {
	border-radius: 10px;
	& ::v-deep .fa-chevron-down {
		transform: scaleY(1);
		transition: all 200ms ease;
	}
	&:hover {
		border: none;
		box-shadow: none;
	}
	&:focus,
	&:active,
	&:active:focus {
		border: none;
		box-shadow: none;
	}
}

.collapse-button-open {
	border: 1px solid #0079ad;
	border-radius: 10px 10px 0px 0px;
	& ::v-deep .fa-chevron-down {
		transform: scaleY(-1);
		transition: all 200ms ease;
	}
	&:hover,
	&:focus,
	&:active {
		border: none;
		box-shadow: none;
	}
}

.button-link ::v-deep svg {
	font-size: 12px;
}

.collapse-button ::v-deep p {
	padding-top: 0px;
	padding-bottom: 0px;
	margin-bottom: 0px;
}

.collapse-content {
	border-radius: 0px 10px 10px 10px;
}
.collapse-content-background {
	background-color: $white;
	border: 1px solid #e9e9e9;
}
</style>
